@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-notoSanCJK;
}

.ant-form-item-explain-error {
  @apply font-notoSanCJK;
}

.ant-form-item-required {
  @apply font-notoSanCJK;
}

.ant-notification-notice-message {
  @apply font-notoSanCJK;
}

.ant-notification-notice-description {
  @apply font-notoSanCJK;
}

.ant-select-arrow svg {
  @apply fill-white;
}

.ant-select-selection-item {
  @apply font-notoSanCJK text-white;
}

.ant-input {
  @apply font-notoSanCJK;
}

.ant-popover-inner {
  @apply !rounded-none;
}

.ant-input-group-addon .ant-btn {
  @apply bg-colorSecondaryHigher !text-white;
}

.ant-drawer-body {
  @apply !p-4;
}

.ant-drawer-header {
  @apply !p-4;
}

.ant-card-body {
  @apply !p-1.5 !font-notoSanCJK;
}

.ant-collapse-header {
  @apply !font-notoSanCJK;
}

.ant-collapse-content-box {
  @apply !font-notoSanCJK;
}

.ant-breadcrumb-link {
  @apply !font-notoSanCJK;
}

.ant-space-compact {
  @apply !drop-shadow-lg;
}

.ant-input-group-addon {
  @apply !bg-white !border-none !border-0;
}

.ant-select-selection-item {
  @apply !text-black;
}

.ant-select-arrow svg {
  @apply !fill-black;
}

.ant-input-affix-wrapper {
  @apply !bg-white !border-none !border-0 !h-10;
}

.ant-input-group-addon button {
  @apply !border-none !border-0 h-10 hover:!bg-colorSecondaryHigher;
}

.custom-search .ant-input-group-addon button {
  @apply !border-none !border-0 !h-8 hover:!bg-colorSecondaryHigher bg-colorButtonSearch;
}

.scrollbar-custom::-webkit-scrollbar {
  @apply h-2 cursor-move;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-r from-colorLinearF to-colorLinearT rounded-lg;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  @apply bg-colorSecondaryHigher;
}

/* .scrollbar-custom::-webkit-scrollbar-track {
  @apply bg-colorSecondary rounded-lg;
} */

ul {
  list-style-type: disc;
  margin-left: 20px;
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
}

li {
  margin-bottom: 8px;
}

.ql-align-center {
  display: flex !important;
  text-align: center !important;
  justify-content: center !important;
}

.ql-align-center img {
  max-width: 100%;
  width: 20rem;
  height: 280px;
  margin-left: auto;
  margin-right: auto;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-size-huge {
  font-size: 2rem;
}

.ql-size-large {
  font-size: 1.25rem;
}

.ql-size-normal {
  font-size: 1rem;
}

.ql-size-small {
  font-size: 0.875rem;
}

.ql-size-huge,
.ql-size-large,
.ql-size-normal,
.ql-size-small {
  line-height: 1.5;
}
